/*
 * 업무구분: 활동
 * 화 면 명: MSPCCT702P
 * 화면설명: 최근 라이프맵핑검색 
 * 작 성 일: 2022.12.01
 * 작 성 자: 김영수
 */
<template>
  <ur-page-container title="최근 라이프맵핑검색" :show-title="false" type="popup">
    <ur-box-container direction="column" alignV="start" class="ns-sch-area">
   
      <!-- 검색조건 영역 : 접근한 화면에 따라 show를 변경해줌 -->

      <!-- 전체고객 -->
      <ur-box-container v-if="pPage === 'MSPCT701M'" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-layout-fixed">
        
        <div class="ns-rec-box" style="margin-top:10px;">
          <mo-text-field v-model="searchKeyword" underline placeholder="라이프맵핑 회일명을 입력하세요" @keyup.enter="fn_Search"/>
        </div>
      </ur-box-container>


      <!-- /검색조건 영역 -->

      <!-- 초기화/조회 버튼 -->
      <ur-box-container alignV="start" componentid="ur_box_container_008" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Cancel">취소</mo-button>
          <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Search">조회</mo-button>
        </div>
      </ur-box-container>

      <!-- Toast -->
      <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

      <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
      <div class="ns-height150"></div>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
import SearchOrgForCM from '@/ui/cm/SearchOrgForCM.vue'
import moment from 'moment'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCT702P',
  // 현재 화면 ID
  screenId: 'MSPCT702P',
  // 컴포넌트 선언
  components: {
    MspBottomSelect,
    SearchOrgForCM
  },
  // 화면명
  props: {
    pPage: String, // 부모 페이지명
    pSearchKeyword: String, // 고객명
    pIsMngr: Boolean, // 지점장여부
    pOrgData: { // 조직정보
      type: Object,
      default: {}
    },
    plifeMappFileId: String, // 화일id
    pStartDate: String, // 조회시작일자
    pEndDate: String, // 조회종료일자
  
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      // 공통 변수
      toastMsg: '', // 문자발송 toast 문구
      searchKeyword: '', // 화일명
      lv_ReturnVal: {}, // 리턴값
      nowDate: moment(new Date()).format('YYYY-MM-DD'), // 오늘
      isSucs: false, // 리턴값 세팅여부

      // 조직
      lv_SelectOrg: {}, // 조직 컴포넌트 데이터
      pOrgData702P: {}, // 조직 정보
      isShowCnslt: false, // 담당컨설턴트
      allChk: false, // 조직, 담당컨설턴트 조회완료 여부
    
      startDate: '', // 조회시작일자
      endDate: '', // 조회종료일자

    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {},
  
  /** watch 정의 영역 */
  watch: {},

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // 공통코드 조회
    const lv_Vm = this
    let codeList = []

  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    // 조직정보 세팅
    this.pOrgData702P = this.pOrgData

    switch (this.pPage) {
      // 전체 
      case 'MSPAP701M':
        this.searchKeyword = this.pSearchKeyword
        break
 
    }
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
  
    /******************************************************************************
     * Function명 : fn_Cancel
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Cancel () {

      // 관리자 접근 시 조직정보 초기화 후, 재조회
      //if (this.pIsMngr) this.$refs.refSearchOrg.fn_GetFofRol()
       // 조직정보 세팅
      this.lv_ReturnVal.cnsltEno = !this.$bizUtil.isEmpty(this.lv_SelectOrg.cnslt) ? this.lv_SelectOrg.cnslt.key : '' // 컨설턴트 사번
      this.lv_ReturnVal.dofOrgCd = !this.$bizUtil.isEmpty(this.lv_SelectOrg.dofOrg) ? this.lv_SelectOrg.dofOrg.key : '' // 선택사업부
      this.lv_ReturnVal.fofOrgCd = !this.$bizUtil.isEmpty(this.lv_SelectOrg.fofOrg) ? this.lv_SelectOrg.fofOrg.key : '' // 선택지역단
      this.lv_ReturnVal.hofOrgCd = !this.$bizUtil.isEmpty(this.lv_SelectOrg.hofOrg) ? this.lv_SelectOrg.hofOrg.key : '' // 선택지점
      this.lv_ReturnVal.pOrgData = this.lv_SelectOrg // 조회 조직 데이터


      switch (this.pPage) {
        // 전체고객
        case 'MSPCT701M':
          this.lv_ReturnVal.searchKeyword = ''  // 맵핑화일명

          this.isSucs = true // 리턴값 세팅여부
          this.$emit('onPopupSearch', { rtnData: this.lv_ReturnVal });
          break

      }
    },

    /******************************************************************************
     * Function명 : fn_SetReturnVal
     * 설명       : 팝업 닫힐때, 최종 return 입력값 셋팅
     ******************************************************************************/
    fn_SetReturnVal () {
      this.lv_ReturnVal = {} // 초기화
      this.isSucs = false // 리턴값 세팅여부

      // 조직정보 세팅
       this.lv_ReturnVal.cnsltEno = !this.$bizUtil.isEmpty(this.lv_SelectOrg.cnslt) ? this.lv_SelectOrg.cnslt.key : '' // 컨설턴트 사번
       this.lv_ReturnVal.dofOrgCd = !this.$bizUtil.isEmpty(this.lv_SelectOrg.dofOrg) ? this.lv_SelectOrg.dofOrg.key : '' // 선택사업부
       this.lv_ReturnVal.fofOrgCd = !this.$bizUtil.isEmpty(this.lv_SelectOrg.fofOrg) ? this.lv_SelectOrg.fofOrg.key : '' // 선택지역단
       this.lv_ReturnVal.hofOrgCd = !this.$bizUtil.isEmpty(this.lv_SelectOrg.hofOrg) ? this.lv_SelectOrg.hofOrg.key : '' // 선택지점
      this.lv_ReturnVal.pOrgData = this.lv_SelectOrg // 조회 조직 데이터

      switch (this.pPage) {
        // 전체고객
        case 'MSPCT701M':
          this.lv_ReturnVal.searchKeyword = this.searchKeyword.trim() // 화일명
 
          this.isSucs = true // 리턴값 세팅여부
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 조회 후 팝업 닫음
     ******************************************************************************/
    fn_Search () {
      this.fn_SetReturnVal() // 리턴값 세팅

      if (this.isSucs) {
        this.$emit('onPopupSearch', { rtnData: this.lv_ReturnVal });
      }
    }
  }
}
</script>
