/*
 * 업무구분: 활동
 * 화 면 명: MSPCCT701M
 * 화면설명: 최근 라이프맵핑 
 * 작 성 일: 2022.12.01
 * 작 성 자: 김영수
 */
<template>
  <ur-page-container title="최근 라이프맵핑" :show-title="true" type="subpage" action-type="search" @on-header-left-click="fn_HistoryBack" @action-search-click="fn_OpenMSPCT702P()" @on-scroll-bottom="fn_LoadingData()" :topButton="true" ref="page">
    <!-- 전체체크 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isOneOrMoreCheck" @click="fn_CheckAll()" :disabled="checkDisabled || isEmptyView"></mo-checkbox>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">{{ checkCount > 0 ? checkCount + '명 선택' : '총 ' + cTLifeMappRsltInfoList.length + '명' }}</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>

    <ur-box-container direction="column" alignV="start" class="ns-list-area" >
      <!-- 조회결과 리스트 start -->
      <ur-box-container v-if="!isEmptyView" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
        <mo-list :list-data="cTLifeMappRsltInfoList">
          <template #list-item="{ item }">
            <mo-list-item>
              <!-- 체크박스 -->
              <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItemCount" :disabled="checkDisabled"/>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <!-- 화일명 -->
                     <span class="name txtSkip" :class="item.tmpFileYn === '1' ? 'ns-ftcr-blue' : ''">{{ item.lifeMappFileNm }}</span>  
                  <!-- 등록일 -->
                    <mo-badge class="badge-sample-badge" text="진행중" shape="status" v-if="item.tmpFileYn === '1' ">진행중</mo-badge>   
                           <!-- 칼라지정 :class="item.tmpFileYn === '1'  ? 'orange' : 'blue'" -->
                </div>
                <div class="list-item__contents__info">
                  <!-- 처리일 -->
                  <span>{{ $commonUtil.dateDashFormat(item.aedat) }}</span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>
        <!-- 하단 버튼(전화/문자/일정/메모) -->

         <!-- __prevent-back-close : 바텀시트가 open 되어 있을때 뒤로가기제한 2022-08-05 김미정 프로 -->
        <mo-bottom-sheet ref="bottomSheetPSCM_701M" class="ns-bottom-menu" __prevent-back-close>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative" >
          <div class="list-item__cntents">
            <div class="relative-div">
         		  <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" :disabled="checkDelete" @click="fn_Delconfirm">삭제</mo-button>
          		<mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="checkSearch"  @click="fn_MoveMSPCT703MPage">불러오기</mo-button>
          	</div>
         </div>
         </ur-box-container>
        </mo-bottom-sheet>



      </ur-box-container>   
      <!-- 조회결과 리스트 end -->

      <!-- NoData 영역: start  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역: end  -->
       
      <!-- Toast -->
      <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

      <!-- indexes: 초성검색 수동 세팅 -->
      <!-- <mo-index-scroll class="ns-index-scroll" :style="indexStyle" indexes="Aㄱㄴㄷㄹㅁㅂㅅㅇㅈㅊㅋㅌㅍㅎ" @index-scroll="fn_ClickInitial"/> -->

      <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 : 리스트 존재시에만 적용 -->
      <div v-if="cTLifeMappRsltInfoList.length > 0" class="ns-height40"></div>
      
    </ur-box-container>
  </ur-page-container>
</template>

<script>


/************************************************************************************************
 * 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import moment from 'moment'
import MSPCT702P from '@/ui/ct/MSPCT702P' // 라이프맵핑 목록 검색
//import MSPCT703M from '@/ui/ct/MSPCT703M' // 라이프맵핑 기본정보입력화면
 

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCT701M',
  // 현재 화면 ID
  screenId: 'MSPCT701M',
  // 컴포넌트 선언
  components: {
    MSPCT702P
 
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      // 여기서부터 사용
      pCustPtclrMatrCntnt: String,
      pChnlCustId: String,
      pCnsltNo: String,

      toastMsg: '', // 문자발송 toast 문구

      popup702: {}, // MSP메702P 전체고객 목록 검색
      
      // index-scroll
      indexStyle: {
        top:'150px',
        height:'calc(100vh - 260px)',
        position:'fixed'
      },
      scrollElementId: '',
      isLoading: false,
      isEmptyView: false, // 데이터 없음 화면
      isSearched: false, // 조회이력 저장 변수
      isMngr: false, // 지점장 여부
      checkDisabled: false, // 체크박스 비활성화 여부
      isCustCardExist: true, // 채널아이디 여부

      checkDelete: true,   // 라이프맵핑화일 삭제여부
      checkSearch: true ,  //라이프맵핑화일 조회여부
      userInfo: {}, // 사용자정보 obj
      pOrgData701M: {}, // 최근 조직 조회 데이터
      lv_cnsltNo: '', // 컨설턴트 번호
      isOneOrMoreCheck: false, // 전체선택
 
      lv_inputParamS1: {}, // 전체고객목록 조회 파라미터 Obj
      lv_inputParamS2: {}, // 고객요약정보 조회 파라미터 Obj
      
      member: { // 선택한 고객 정보(메모 팝업에 사용)
        custPtclrMatrCntnt: '', // 고객특이사항
      },
      isDetailSearch: false,
      isInitialSearch: false, // 초성검색 이미지 visible여부
       
      
      cTLifeMappRsltSVO: [], // 라이프맵핑정보 조회용 Service IF VO
      cTLifeMappRsltInfoSVO: [], // 라이프맵핑정보 조회용  VO
      cTLifeMappRsltInfoList: [], // 라이프맵핑정보 리스트 조회용  VO
      searchInitial: '', // 초성검색 변수
      searchFlag: '', // 조회구분체크값 : 초성검색일때 'X'
      pageRowCnt: '15', // 페이지 count
      stndKeyList: [{stndKeyId: 'nextKey', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 15}], // 더보기 변수
      stndKeyVal: '', // 더보기 여부체크
      nextKeyId: '' ,// 더보기 변수
      nextVal: '' ,// 더보기 변수
      searchTranGb: '1',  //화일명/화일id 조회구분 (1:화일id순   2:화일명+화일id순)
      slifeMappFileNm: '',  //화일명 조회 파라메타
      slifeMappFileId: ''   //화일id 조회 파라메타
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    checkCount () {
      console.log("MSPCT701M---> computed") + this.cTLifeMappRsltInfoList.filter(item => item.checked).length
      return this.cTLifeMappRsltInfoList.filter(item => item.checked).length
    }
  },
  
  /** watch 정의 영역 */
  watch: {   
    checkCount () {
      console.log("MSPCT701M---> watch")
      if (this.checkCount === this.cTLifeMappRsltInfoList.length && this.cTLifeMappRsltInfoList.length !== 0) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    console.log("MSPCT701M---> creat")
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호

    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
      this.isMngr = true
    }

    // 라이프맵핑정보조회 
    this.fn_Btn_S1Click()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
   // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    console.log("MSPCT701M---> mount")
    // index Scroll 설정    
    this.scrollElementId = this.$refs.page.getScrollElementId();
    document.getElementById(this.scrollElementId).addEventListener('scroll', (e) =>{
      const scrollTop = e.target.scrollTop;
      this.indexStyle = {
        top: Math.max(150 - scrollTop, 116) + 'px',
        height: `calc(100vh - ${Math.max(260 - scrollTop, 226)}px)`,
        position: 'fixed'
      }
    })

  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    console.log("MSPCT701M---> beforeDestroy")
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    if (this.$refs !== undefined && this.$refs.bottomSheetPSCM_701M !== undefined) {
      this.$bizUtil.showCustomBottomSheet(this, this.$refs.bottomSheetPSCM_701M, false)
    }

  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_CheckSelectedItemCount
     * 설명       : 체크박스 클릭 핸들러
     *             - 선택한 리스트 카운트를 체크한다. 30명 이상 문자전송 불가
     ******************************************************************************/
    fn_CheckSelectedItemCount () {
      console.log("MSPCT701M---> fn_CheckSelectedItemCount")
      this.selectItems = [] // 초기화
      this.selectItems = this.cTLifeMappRsltInfoList.filter(item => item.checked)

  
     // 체크 시 BottomSheet 컨트롤
      // 공통 시트를 가리고 사용자 시트를 보여준다.
      if (this.checkCount > 0) {
        this.$bizUtil.showCustomBottomSheet(this, this.$refs.bottomSheetPSCM_701M, true)
        //this.$refs.showCustomBottomSheet.open()
      } else {
        this.$bizUtil.showCustomBottomSheet(this, this.$refs.bottomSheetPSCM_701M, false)
        //this.$refs.showCustomBottomSheet.close()
      }

    if (this.checkCount === 1) {
        this.checkDelete = false    // 라이프맵핑화일 삭제버튼
        this.checkSearch = false   //라이프맵핑화일 조회버튼
      } else if (this.checkCount > 1)  {
        this.checkDelete = false    // 라이프맵핑화일 삭제버튼
        this.checkSearch = true   //라이프맵핑화일 조회버튼
      } else {
        this.checkDelete = true    // 라이프맵핑화일 삭제버튼
        this.checkSearch = true   //라이프맵핑화일 조회버튼
      }
 
      // 30개 체크 확인
      if (this.checkCount > 30) {
        // window.fdpbridge.exec('ToastPlugin', {message: '이미 30개를 선택했습니다.\n한번에 최대 30개까지 선택가능합니다.'}, () => {}, () => {})
        this.toastMsg = '이미 30개를 선택했습니다.\n한번에 최대 30개까지 선택가능합니다.'
        this.$refs.snackbar.show({ text: this.toastMsg }) // '이미 30개를 선택했습니다.\n한번에 최대 30개까지 선택가능합니다.'
      }
    },    
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      console.log("MSPCT701M---> fn_HistoryBack")
      if (this.isSearched) {
        // 조회를 한번이라도 한 경우 전체 검색

        // 초기화
        this.pOrgData701M = {} // 조직 조회 데이터
        this.slifeMappFileNm = '' // 맵핑화일명명
        this.slifeMappFileId = '' //  맵핑화일ID
        this.searchTranGb = '1';
        this.isSearched = false // 검색이력 초기화

        // 바텀시트 초기화
        if (this.$refs !== undefined && this.$refs.bottomSheetPSCM_701M !== undefined) {
          this.$bizUtil.showCustomBottomSheet(this, this.$refs.bottomSheetPSCM_701M, false)
        }

        // 재조회
        this.fn_Btn_S1Click()
      } else {
        // 조회를 하지 않았으면 뒤로가기
        this.$router.go(-1)
      }
    },
   
    /******************************************************************************
     * Function명 : fn_Btn_S1Click
     * 설명       : 고객명 조회
     ******************************************************************************/
    fn_Btn_S1Click () {
       console.log("MSPCT701M---> fn_Btn_S1Click--1")
      this.stndKeyList = [{stndKeyId: 'nextKey', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 15}]
      this.stndKeyVal = '' // 더보기 최초조회
      this.cTLifeMappRsltInfoList = []
      this.isDetailSearch = false
      this.isInitialSearch = false // 초성검색 이미지 visible여부
      this.isEmptyView = false // 데이터가 없습니다 visible 여부
      this.searchFlag = '' // 조회구분 체크값 (자음검색일땐 'X')

      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
      if (this.pOrgData701M.cnslt !== undefined && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData701M.cnslt.key) {
          this.checkDisabled = true
        } else {
          this.checkDisabled = false
        }
      } else {
        // 초기 접근 시
        this.checkDisabled = false
      }
      console.log("MSPCT701M---> fn_Btn_S1Click--2")
      // 전체고객목록 조회

      this.fn_SelListAllCust(this.slifeMappFileNm ,this.slifeMappFileId, '' )
    },

    /******************************************************************************
     * Function명 : fn_SetParamS1
     * 설명       : 파라미터 세팅(전체고객목록 조회)
     ******************************************************************************/
    fn_SetParamS1 () {

      console.log("MSPCT701M---> fn_SetParamS1")

      this.lv_inputParamS1= {} // Input 객체 초기화

      // 지점장 조회시 구분
      if (this.pOrgData701M.cnslt !== undefined && this.isMngr === true) {
        this.lv_inputParamS1.userEno  = this.pOrgData701M.cnslt.key // 선택한 컨설턴트 번호
        this.lv_inputParamS1.fofOrgNo = this.pOrgData701M.fofOrg.key // 선택한 지점조직번호
        this.lv_inputParamS1.userEno  = this.userInfo.userId // 컨설턴트 번호
      } else {
        this.lv_inputParamS1.userEno  = this.userInfo.userId // 컨설턴트 번호
        this.lv_inputParamS1.fofOrgNo = this.userInfo.onpstFofOrgNo // 지점조직번호
      }

    },

    /******************************************************************************
     * Function명 : fn_SelListAllCust
     * 설명       : 전체 라이프맵핑목록 조회(최초 로딩 시 실행)
     ******************************************************************************/
    fn_SelListAllCust (fileNm, fileId, nextVal) {
       console.log("MSPCT701M---> fn_SelListAllCust")
      
      // 마지막 페이지 return
      if (this.stndKeyVal === 'end') return

      const lv_Vm = this      
      const auth = 'S'

      let   trnstId = 'txTSSCT70S1' 
      if (this.searchTranGb === '2') {
            trnstId = 'txTSSCT70S6'
      } 
      let pParams = {}

      // 파라미터 세팅
      this.fn_SetParamS1()
      this.lv_inputParamS1.lifeMappFileNm     = (fileNm !== undefined) ? fileNm : '' // 화일명 
      this.lv_inputParamS1.lifeMappFileId     = (fileId !== undefined) ? fileId : '' // 화일id 
      this.lv_inputParamS1.NextKeyId          = (nextVal !== undefined) ? nextVal : '' // 다음조화 값  

      // 로딩이미지 visible 여부 세팅
      // let isLoadingImgVisible = this.isInitialSearch

      this.isLoading = true
      this.lv_TotalCnt = 0

      pParams = this.lv_inputParamS1
  
     // setTimeout(function () {
     //   lv_Vm.isInitialSearch = false // 초성검색 이미지 visible false처리
     // }, 1000)  cTLifeMappRsltSVO
      console.log("MSPCT701M---> fn_SelListAllCust ===>  post")
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            let responseList = response.body.cTLifeMappRsltInfoList

            for (let i = 0; i < responseList.length; i++) {
              responseList[i].checked = false
            }

             // 다음조회 리스트 더하기 
            if ((response.body.nextKeyId !== '') || (responseList.length > 0 )) {
              for (let i = 0; i < responseList.length; i++) {
                  if (responseList[i].tmpFileYn  === '1' ) {
                       responseList[i].lifeMappFileNm =  ('임시_' + responseList[i].mobslChnlCustNm.trim() )
                  }
                       lv_Vm.cTLifeMappRsltInfoList.push(responseList[i])
              }
            } else { // 1페이지 보기 
              lv_Vm.cTLifeMappRsltInfoList = responseList
            }

            if (responseList.length === 0) {
              lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            } else {
              lv_Vm.isEmptyView = false // 데이터 조회  visible false

              // 다음조회 키값  편집
              if (response.body.nextKeyId === null ) {
                  lv_Vm.nextKeyId  = ''  
               } else {
                 lv_Vm.nextKeyId  = response.body.nextKeyId
               }

              // 다음 데이터 존재시 more, 없으면 end
               if (lv_Vm.nextKeyId === null ||  lv_Vm.nextKeyId.trim() === '') {
                    lv_Vm.stndKeyVal = 'end'
               } else {
                     lv_Vm.stndKeyVal = 'more'
                }

            }
          } else {
            lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }

          let resultList = lv_Vm.cTLifeMappRsltInfoList
          lv_Vm.lv_TotalCnt = (resultList === null) ? 0 : resultList.length
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_LoadingData
     * 설명       : 다음조회목록 infinite 로딩
     ******************************************************************************/
    fn_LoadingData () {

      console.log("MSPCT701M---> fn_LoadingData")
      // 더보기 시 전체체크 되어있으면 전체체크 해제
      if (this.isOneOrMoreCheck && this.stndKeyVal !== 'end') {
        this.isOneOrMoreCheck = false
 
      }

      // 전체고객목록 조회  
      this.fn_SelListAllCust(this.slifeMappFileNm, this.slifeMappFileId, this.nextKeyId)
    },

    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll () {
      console.log("MSPCT701M---> fn_CheckAll")

      this.$nextTick(() => {
        if (this.cTLifeMappRsltInfoList.length > 0) { // 리스트 존재시에만 작동
          for (let n in this.cTLifeMappRsltInfoList) {
            if (this.isOneOrMoreCheck) {
              this.cTLifeMappRsltInfoList[n].checked = true
            } else {
              this.cTLifeMappRsltInfoList[n].checked = false
            }
          }

          // 체크된 값 채널아이디 여부 확인
          if (this.cTLifeMappRsltInfoList.filter(item => (item.checked === true && item.lifeMappFileId.trim() === '')).length === 0) {
            this.isCustCardExist = true
          } else {
            this.isCustCardExist = false
          }
  
         this.fn_CheckSelectedItemCount ()
        }
      })
      
    },

   /*********************************************************
     * Function명: fn_MapDataDelete
     * 설명: 라이프맵핑화일목록 중 삭제 처리   
     *********************************************************/
    fn_MapDataDelete  () {
           console.log("MSPCT701M---> fn_MapDataDelete")
      
      const ld_Vm = this 
      let   trnstId = 'txTSSCT70S5' 
      let pParams = {}

      // 파라미터 세팅
      this.fn_SetParamS1()

      this.selectItems = []
      this.selectItems = this.cTLifeMappRsltInfoList.filter(item => item.checked)

      if (this.selectItems.length > 30) { // 선택한 고객이 30명 이상이면 return
        this.toastMsg = '최대 30명까지만 삭제 가능합니다.'
        this.$refs.snackbar.show({ text: this.toastMsg }) // 
      } 

      this.lv_inputParamS1.cTLifeMappRsltInfoList = this.selectItems   //  삭제 대상 리스트  파라메타 영역에 편집 
      for (let i = 0; i < this.selectItems.length; i++) {
           this.lv_inputParamS1.cTLifeMappRsltInfoList[i].lifeMappFileDeltYn = '1'
      }
      
      pParams = this.lv_inputParamS1

         console.log("MSPCT701M---> fn_MapDataDelete ===>  post")

      this.post(ld_Vm, pParams, trnstId, 'D')
          .then(function (response) {
           // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            let responseList = response.body.cTLifeMappRsltInfoList

            if (responseList.length === 0) {
              ld_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            } else {
              ld_Vm.isEmptyView = false // 데이터 조회  visible false
            }
          } else {
            ld_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            ld_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
 
          ld_Vm.toastMsg =  ld_Vm.selectItems.length + ' 건  삭제 되었습니다. '
          ld_Vm.$refs.snackbar.show({ text: ld_Vm.toastMsg })   

          // 재조회
            ld_Vm.fn_Btn_S1Click()
          
          // 바텀시트 초기화
          if (ld_Vm.$refs !== undefined && ld_Vm.$refs.bottomSheetPSCM_701M !== undefined) {
            ld_Vm.$bizUtil.showCustomBottomSheet(ld_Vm, ld_Vm.$refs.bottomSheetPSCM_701M, false)
          }
        })
        .catch(function (error) {
          ld_Vm.isEmptyView = true // 데이터가 없습니다 visible true
          window.vue.error(error)
        })                  
    },
   /*********************************************************
     * Function명: fn_OpenMAP702P
     * 설명: 라이프맵핑화일목록  검색 팝업 호출
     *********************************************************/
    fn_OpenMSPCT702P () {
       console.log("MSPAP701Mㅡ---> fn_OpenMSPCT702P")
 
      const lp_Vm = this  
      this.popup702 = this.$bottomModal.open(MSPCT702P, {
        properties: {
          pPage: 'MSPCT701M',
          pOrgData: this.pOrgData701M, // 최근 조직 조회 데이터
          pSearchKeyword: this.slifeMappFileNm, // 화일명
          pIsMngr: this.isMngr, // 지점장 여부
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            lp_Vm.$bottomModal.close(this.popup702)
            if (lp_Vm.$refs !== undefined && lp_Vm.$refs.bottomSheetPSCM_701M !== undefined) {
              lp_Vm.$bizUtil.showCustomBottomSheet(lp_Vm, lp_Vm.$refs.bottomSheetPSCM_701M, false)
            }
 
            lp_Vm.slifeMappFileNm = pData.rtnData.searchKeyword
            if (lp_Vm.slifeMappFileNm === null ||  lp_Vm.slifeMappFileNm.trim() === '') {
                lp_Vm.searchTranGb = '1'   // 화일id순 조회
           } else {
                lp_Vm.searchTranGb = '2'   // 황일명+화일id순 조회   
            }
       

            // 마지막 조직 조회 데이터 셋팅
            lp_Vm.pOrgData701M = pData.rtnData.pOrgData

            // 조회이력 저장
            lp_Vm.isSearched = false

            // 고객명 조회
            this.fn_Btn_S1Click()
          }
        }
      })
    },
 
    /******************************************************************************
    * Function명 : fn_MoveMspcm019mPage
    *  설명: 라이프맵핑 기본정보 입력 화면 이동 
    ******************************************************************************/
    fn_MoveMSPCT703MPage () {
 
      console.log("MSPCT701M---> fn_MoveMSPCT703Page")

      this.selectItems = []
      this.selectItems = this.cTLifeMappRsltInfoList.filter(item => item.checked)

      if (this.selectItems.length > 1) { // 한명의 고객만 불러오기 선택가능 
        this.toastMsg = '1명의 고객 화일만 불러오기 가능합니다.'
        this.$refs.snackbar.show({ text: this.toastMsg }) // '최대 30명까지만 문자발송 가능'
      } 

      this.lv_inputParamS2 = [] // 초기화
      this.lv_inputParamS2 = this.selectItems

      this.$router.push(
        {
          name: 'MSPCT703M',
          params: {
            name: 'MSPCT701M',
            ctLifeMappRsltInfoSVO : this.lv_inputParamS2[0]
          }
        }
      )
    },
/*********************************************************
     * Function명: fn_MapDataDelete
     * 설명: 라이프맵핑화일목록 중 삭제 처리   확인 창  
*********************************************************/
    fn_Delconfirm  () {
   
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            //single: true,
            title: "확인",
           content: ' 삭제하시겠습니까 ? ', 
            title_pos_btn: "예",
            title_neg_btn: "아니요"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_AlertPop)
              this.fn_MapDataDelete  ()
            },
            onPopupClose: () => {
              this.$bottomModal.close(lv_AlertPop)
 
            }
          }
        })
    },
    /******************************************************************************
     * Function명 : fn_ClickInitial
     * 설명       : 초성 클릭 핸들러
     ******************************************************************************/
    fn_ClickInitial (keyword) {
      if (this.isInitialSearch === true) {
        return
      }

      this.isInitialSearch = true // 초성검색 이미지 visible여부
      this.isEmptyView = false // 데이터가 없습니다 visible 여부
      this.searchFlag = 'X' // 조회구분 체크값 (자음검색일땐 'X')

      this.fn_ValiInitial(keyword) // 초성값 세팅
      this.searchTranGb = '2'
      this.searchKeyword = '' // 고객명 초기화
      this.slifeMappFileId = ''

      this.stndKeyList = [{stndKeyId: 'nextKey', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 15}]
      this.stndKeyVal = '' // 더보기 최초조회
      this.cTLifeMappRsltInfoList = []
      this.isDetailSearch = false

      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
     if (this.pOrgData701M.cnslt !== undefined && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData701M.cnslt.key) {
          this.checkDisabled = true
        } else {
          this.checkDisabled = false
        }
      } 

      // 조회이력 저장
      this.isSearched = true

      // 전체고객목록 조회
      this.fn_SelListAllCust(this.searchInitial, this.slifeMappFileId, '')
    },
    
    /******************************************************************************
     * Function명 : fn_ValiInitial
     * 설명       : 초성값 세팅
     ******************************************************************************/
    fn_ValiInitial(keyword) {
      this.searchInitial = this.$cmUtil.getNameInitial(keyword)
    },
    
    
  }
}
</script>
    